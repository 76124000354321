module.exports = function initGallery() {
  var imageContainer = document.querySelector('.images__content')

  document.body.addEventListener(
    'load',
    function(event) {
      if (event.target.tagName == 'IMG') {
        onResize()
      }
    },
    // use capture
    true
  )

  var nodes

  var raf,
    gallerySize,
    areaSize,
    portraitMode = false,
    direction = -1,
    nodes,
    images,
    startY,
    y = 0,
    scrollOffset = 0

  return {
    start,
    stop
  }

  function start() {
    cancelAnimationFrame(raf)
    window.addEventListener('wheel', onMouseWheel)
    window.addEventListener('resize', onResize)
    onResize()
    animate()
  }

  function stop() {
    cancelAnimationFrame(raf)
    window.removeEventListener('wheel', onMouseWheel)
    window.removeEventListener('resize', onResize)
  }

  function onResize() {
    nodes = document.querySelectorAll('.images__content img')

    portraitMode = window.innerWidth / window.innerHeight > 1

    areaSize = portraitMode ? window.innerHeight : window.innerWidth

    if (typeof startY === 'undefined') {
      startY = areaSize * 2
    }

    images = []

    var columns = [
      { index: 0, size: 0, items: [] },
      { index: 1, size: areaSize / 2, items: [] },
      portraitMode ? null : { index: 2, size: areaSize / 2, items: [] }
    ].filter(Boolean)

    var widthAttribute = portraitMode ? 'width' : 'height'
    var heightAttribute = portraitMode ? 'height' : 'width'

    var colWidth =
      imageContainer.getBoundingClientRect()[widthAttribute] / columns.length -
      30

    nodes.forEach(function(node, index) {
      node.style.transform = 'none'
      node.style.position = 'relative'

      var bounds = node.getBoundingClientRect()

      if (!bounds.height) {
        return
      }

      var image = {
        node: node,
        index: index,
        display: false,
        width: bounds.width,
        height: bounds.height,
        size: (Math.sin(index * 0.76) + 1) * 0.5
      }

      var r = colWidth / Math.max(bounds.width, bounds.height)

      node.style.width = bounds.width * r + 'px'
      node.style.height = bounds.height * r + 'px'

      images.push(image)
    })

    images.forEach(function(image, index) {
      var node = image.node
      var bounds = node.getBoundingClientRect()

      // retrieve smallest column
      var col = columns.sort(function(a, b) {
        return a.size > b.size ? 1 : a.size < b.size ? -1 : 0
      })[0]

      image.position = [
        20 + 20 * col.index + col.index * colWidth,
        col.size //+ areaSize * 0.33
      ]

      if (col.size < areaSize) {
        // image.display = true
      }

      col.size +=
        bounds[heightAttribute] +
        areaSize * 0.2 +
        Math.random() * areaSize * 0.2
      col.items.push(image)

      node.style.position = 'absolute'
      node.style.top = 0
      node.style.left = 0
      node.style.zIndex = image.size > 0.5 ? 3 : 0
    })

    columns
      .sort(function(a, b) {
        return a.size > b.size ? 1 : a.size < b.size ? -1 : 0
      })
      .reverse()

    gallerySize = columns[0].size
  }

  function onMouseWheel(e) {
    var delta = -e.deltaY
    scrollOffset += delta / 2
    direction = Math.sign(delta)
  }

  function animate() {
    scrollOffset += direction * 2

    images.forEach(function(image, index) {
      var itemSize = portraitMode ? image.height : image.width

      var y = image.position[1] + scrollOffset
      y = (y + gallerySize * 1000) % gallerySize
      y = y * 1 - areaSize

      // apply parallax if within window
      var parallaxOffset = 0

      if (y > -itemSize && y < areaSize) {
        parallaxOffset =
          ((y - areaSize * 0.5) / areaSize) * image.size * areaSize

        if (!image.display && image.init) {
          image.display = true
          image.node.style.opacity = 1
          image.node.style.visibility = 'visible'
        }
      } else {
        if (!image.init) {
          image.init = true
        }
        if (image.display) {
          image.display = false
          image.node.style.opacity = 0
          image.node.style.visibility = 'hidden'
        }
      }

      y += parallaxOffset * 1
      var x = image.position[0]

      image.node.style.transform = portraitMode
        ? `translate3d(${x}px,${y}px,0px)`
        : `translate3d(${y}px,${x}px,0px)`
    })

    raf = requestAnimationFrame(animate)
  }
}
