const initGallery = require('gallery/gallery')

var gallery = initGallery()
init()

function init() {
  initResize()
  initState()
  initToggles()
}

function initState() {
  var nav = ['/09', '/27', '/39']
  var state = {
    pathname: window.location.pathname
  }

  // setup refs and listeners

  var nav = document.querySelector('.col-navigation ul')
  var items = document.querySelectorAll('.col-navigation li > a')

  items.forEach(function(item, index) {
    item.addEventListener('click', onItemClick)
  })

  window.addEventListener('load', function() {
    setTimeout(function() {
      setPath(state.pathname)
    }, 10)

    setTimeout(function() {
      setReady()
    }, 500)
  })

  window.addEventListener('resize', function() {
    setPath(state.pathname)
  })

  var resetWheelTimeout

  window.addEventListener('wheel', function(e) {
    var delta = e.deltaY

    var navX = 0
    //document.querySelector('.col-navigation').getBoundingClientRect().left

    if (!resetWheelTimeout && e.clientX > navX) {
      var direction = Math.sign(delta)
      var index = 0
      items.forEach(function(a, i) {
        if (a.getAttribute('data-id') === state.pathname.replace(/^\//, '')) {
          index = i
        }
      })
      var nextItem
      items.forEach(function(a, i) {
        if (i == index + direction) {
          nextItem = a
        }
      })
      if (nextItem) {
        var nextId = nextItem.getAttribute('data-id')
        setPath(nextId)
      }
    }

    clearTimeout(resetWheelTimeout)
    resetWheelTimeout = setTimeout(function() {
      resetWheelTimeout = null
    }, 50)
  })

  window.onpopstate = function(evt) {
    setPath(evt.state.pathname)
  }

  function setReady() {
    document.body.classList.add('ready')
  }

  function setPath(pathname) {
    var cleanPath = (pathname || '').replace(/^\/|\/$/, '').match(/(09|27|39)/)

    if (state.pathname !== pathname) {
      document.body.classList.add('page-transition-start')
      setTimeout(function() {
        document.body.classList.remove('page-transition-start')
      }, 220)
    }

    state.pathname = pathname

    cleanPath = (cleanPath && cleanPath[0]) || '09'

    if (cleanPath) {
      var navItem = items.forEach(item => {
        if (item.getAttribute('data-id') === cleanPath) {
          selectItem(item)
        }
      })
    } else {
      selectItem(items[0], true)
    }
  }

  function onItemClick(evt) {
    evt.preventDefault()
    var target = evt.currentTarget
    var href = target.getAttribute('href')

    window.history.pushState({ pathname: href }, 'TITLE' + href, href)
    setPath(href)
  }

  function selectItem(item, positionOnly) {
    // removes current state

    items.forEach(function(item) {
      item.parentElement.classList.remove('selected')
    })

    var offset = item.parentElement.offsetTop
    var bounds = item.getBoundingClientRect()
    var height = bounds.height

    nav.style.transform = `translate3d(0, ${offset * -1}px, 0)`
    !positionOnly && item.parentElement.classList.add('selected')
  }
}

function initToggles() {
  var state = { toggles: { images: true, info: true } }

  var imageToggle = document.querySelector('.toggle-images')
  var infoToggle = document.querySelector('.toggle-info')
  var body = document.querySelector('body')

  imageToggle.addEventListener('click', function() {
    state.toggles.images = !state.toggles.images
    displayImages(state.toggles.images)
  })

  function displayImages(value) {
    if (value) {
      body.classList.add('display-images')
      gallery.start()
    } else {
      body.classList.remove('display-images')
      gallery.stop()
    }
  }

  infoToggle.addEventListener('click', function() {
    state.toggles.info = !state.toggles.info
    displayInfo(state.toggles.info)
  })

  function displayInfo(value) {
    if (value) {
      body.classList.add('display-info')
    } else {
      body.classList.remove('display-info')
    }
  }

  displayImages(state.toggles.images)

  displayInfo(state.toggles.info)
}

function initResize() {
  window.addEventListener('resize', onResize)
  onResize()

  function onResize() {
    var vw = window.innerWidth
    var vh = window.innerHeight

    var root = document.querySelector('html')

    var size, heading_px

    if (vw / vh < 1 || vw < 960) {
      var padding = 25 * 2
      // need to fit the word 'room' in width
      heading_px = 350 * ((vw - padding) / 1100)
    } else {
      var text_size = 150
      var vs = (vh / 940) * text_size
      var hs = (vw / 1440) * text_size

      heading_px = Math.max(80, Math.min(vs, hs))
    }
    root.style.setProperty('--h1-height', heading_px + 'px')

    var gallery = document.querySelector('.col-gallery')
    root.style.setProperty(
      '--gallery-width',
      gallery.getBoundingClientRect().width + 'px'
    )
  }
}

function initImages() {
  //
}
